const initialState = {
  listPengajuan: [],
  kebutuhanDropdown: [],
  rekeningDropdown: [],
  listSimpanan: [],
};

const pengajuanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLPENGAJUAN":
      return {
        ...state,
        listPengajuan: data,
      };
    case "SET_KEBUTUHANDROPDOWN":
      return {
        ...state,
        kebutuhanDropdown: data,
      };
    case "SET_REKENINGDROPDOWN":
      return {
        ...state,
        rekeningDropdown: data,
      };
    case "SET_ALLSIMPANAN":
      return {
        ...state,
        listSimpanan: data,
      };
    default:
      return state;
  }
};
export default pengajuanReducer;
