import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/pengajuanWeb/getPengajuan?page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPENGAJUAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahPengajuan(data) {
  try {
    const response = await baseAxios.post("/pengajuanWeb/addPengajuan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getKebutuhanDropdown(dispatch, data, history) {
  try {
    const response = await baseAxios("/kebutuhanWeb/getKebutuhanDropdown", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_KEBUTUHANDROPDOWN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getRekeningDropdown(dispatch, data, history) {
  try {
    const response = await baseAxios("/rekeningWeb/getRekeningDropdown", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_REKENINGDROPDOWN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function cekSo(dispatch, data, history) {
  try {
    const response = await baseAxios.post(`/pengajuanWeb/cekIdSo`, data, {
      headers: { token: localStorage.getItem("token") },
    });

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cekPo(dispatch, data, history) {
  try {
    const response = await baseAxios.post(`/pengajuanWeb/cekIdPo`, data, {
      headers: { token: localStorage.getItem("token") },
    });

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function approvePengajuan(data) {
  try {
    const response = await baseAxios.post(
      "/pengajuanWeb/approvePengajuan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getSimpananRekening(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/pengajuanWeb/getSimpananByRekening?page=${data.page}&limit=${data.limit}&idRekening=${data.idRekening}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLSIMPANAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

// export async function editStatusSj(data) {
//   try {
//     const response = await baseAxios.post(
//       "/suratJalanWeb/updateStatusSj",
//       data,
//       {
//         headers: {
//           token: localStorage.getItem("token"),
//         },
//       }
//     );
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

// export async function cetakSj(data) {
//   try {
//     const response = await baseAxios(
//       `/suratJalanWeb/cetakSjPdf?idSo=${data.idSo}`,
//       {
//         headers: { token: localStorage.getItem("token") },
//       }
//     );
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

// export async function exportSj(dispatch, data) {
//   try {
//     const response = await baseAxios(
//       `/suratJalanWeb/exportSj?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
//       {
//         headers: {
//           token: localStorage.getItem("token"),
//         },
//         responseType: "blob",
//       }
//     );

//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }
