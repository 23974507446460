const initialState = {
  allOrderStatus: null,
  totalPl: 0,
  totalPo: 0,
  totalPlProgres: 0,
  totalPoProgres: 0,
  totalOmset: 0,
  totalDp: 0,
  totalLunas: 0,
  totalBelumBayar: 0,
  listDp: [],
  listLunas: [],
  listBelumBayar: [],
  grafikOmset: [],
  grafikStok: [],
  grafikTopProduk: [],
  nominalRekening: [],
};

const dashboardReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_ALLORDERSTATUS":
      return {
        ...state,
        allOrderStatus: data,
      };
    case "SET_TOTALPL":
      return {
        ...state,
        totalPl: data,
      };
    case "SET_TOTALPO":
      return {
        ...state,
        totalPo: data,
      };
    case "SET_TOTALPLPROGRES":
      return {
        ...state,
        totalPlProgres: data,
      };
    case "SET_TOTALPOPROGRES":
      return {
        ...state,
        totalPoProgres: data,
      };
    case "SET_TOTALOMSET":
      return {
        ...state,
        totalOmset: data,
      };
    case "SET_TOTALDP":
      return {
        ...state,
        totalDp: data,
      };
    case "SET_TOTALLN":
      return {
        ...state,
        totalLunas: data,
      };
    case "SET_TOTALBELUMBAYAR":
      return {
        ...state,
        totalBelumBayar: data,
      };
    case "SET_LISTDP":
      return {
        ...state,
        listDp: data,
      };
    case "SET_LISTLUNAS":
      return {
        ...state,
        listLunas: data,
      };
    case "SET_LISTBELUMBAYAR":
      return {
        ...state,
        listBelumBayar: data,
      };
    case "SET_GRAFIKOMSET":
      return {
        ...state,
        grafikOmset: data,
      };
    case "SET_GRAFIKSTOK":
      return {
        ...state,
        grafikStok: data,
      };
    case "SET_GRAFIKTOPPRODUK":
      return {
        ...state,
        grafikTopProduk: data,
      };
    case "SET_TOTALNOMINALREKENING":
      return {
        ...state,
        nominalRekening: data,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
