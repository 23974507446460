import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getKategoriAsset,
  tambahKategoriAsset,
  nonAktifKategoriAsset,
  deleteKategoriAsset,
} from "stores";
import CurrencyInput from "react-currency-input-field";

function KategoriAsset() {
  const dispatch = useDispatch();
  const kategoriAsset = useSelector((state) => state.kategoriAssetReducer);
  const [listKategoriAsset, setListKategoriAsset] = React.useState([]);
  const [namaKategori, setNamaKategori] = React.useState("");
  const [singkatan, setSingkatan] = React.useState("");
  const [pembagi, setPembagi] = React.useState(1);
  const [jenisBatas, setJenisBatas] = React.useState("");
  const [batas, setBatas] = React.useState(0);
  const [idKategori, setIdKategori] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  //   const [data, setData] = React.useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={() => {
                Swal.fire({
                  title: `Apakah anda yakin untuk ${
                    row.softDelete ? "mengaktifkan" : "menonaktifkan"
                  } kategori ini?`,
                  text: "Pastikan yang anda lakukan sudah benar!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok!",
                  cancelButtonText: "Batal!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Loading...!",
                      text: "Proses ubah status kategori sedang dilakukan,tunggu proses hingga selesai!",
                      icon: "warning",
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    nonAktifKategoriAsset({
                      idKategoriAsset: row.idKategoriAsset,
                      softDelete: row.softDelete ? false : true,
                    }).then((response) => {
                      if (response.data.status === 200) {
                        Swal.fire({
                          title: response.data.message,
                          icon: "success",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        getKategoriAsset(dispatch);
                      } else {
                        setPending(false);
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: !response.data
                            ? "Gagal ubah status kategori"
                            : response.data.message,
                        });
                      }
                    });
                  }
                });
              }}
              style={{
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                margin: 10,
              }}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-48903503">
                    {row.softDelete ? "Non-Aktif" : "Aktif"}
                  </Tooltip>
                }
              >
                <i
                  style={{
                    color: row.softDelete ? "red" : "green",
                    fontSize: 16,
                  }}
                  className={row.softDelete ? "fas fa-times" : "fas fa-check"}
                ></i>
              </OverlayTrigger>
            </div>
            <div
              onClick={() => {
                Swal.fire({
                  title: `Apakah anda yakin untuk menghapus kategori ini?`,
                  text: "Pastikan yang anda lakukan sudah benar!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok!",
                  cancelButtonText: "Batal!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Loading...!",
                      text: "Proses hapus kategori sedang dilakukan,tunggu proses hingga selesai!",
                      icon: "warning",
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    deleteKategoriAsset({
                      idKategoriAsset: row.idKategoriAsset,
                    }).then((response) => {
                      if (response.data.status === 200) {
                        Swal.fire({
                          title: response.data.message,
                          icon: "success",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        getKategoriAsset(dispatch);
                      } else {
                        setPending(false);
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: !response.data
                            ? "Gagal hapus kategori"
                            : response.data.message,
                        });
                      }
                    });
                  }
                });
              }}
              style={{
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                margin: 10,
              }}
            >
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
              >
                <i
                  style={{ color: "#FF0000", fontSize: 16 }}
                  className="fas fa-trash"
                ></i>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      name: "ID Kategori",
      width: "200px",
      selector: (row) => row.idKategoriAsset,
    },
    {
      name: "Nama Kategori",
      width: "200px",
      selector: (row) => row.namaKategori,
    },
    {
      name: "Kode",
      width: "150px",
      center: true,
      selector: (row) => row.kode,
    },
    {
      name: "Pembagi (Bulan)",
      width: "200px",
      center: true,
      selector: (row) => row.pembagi,
    },
    {
      name: "Tahun",
      width: "100px",
      center: true,
      selector: (row) => row.tahun,
    },
    {
      name: "Jenis Batas",
      width: "200px",
      center: true,
      selector: (row) => (row.jenisBatas === "atas" ? "Di Atas" : "Di Bawah"),
    },
    {
      name: "Batas",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.batas);
        return formattedValue;
      },
    },
  ];

  const addKategori = () => {
    setPending(true);
    tambahKategoriAsset({
      namaKategori: namaKategori,
      kode: singkatan.toUpperCase(),
      pembagi: parseInt(pembagi),
      jenisBatas: jenisBatas.value,
      batas: parseInt(batas),
    }).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setNamaKategori("");
        setSingkatan("");
        setPembagi(0);
        setJenisBatas("");
        setBatas(0);
        setModal(false);
        getKategoriAsset(dispatch);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan kategori"
            : response.data.message,
        });
      }
    });
  };

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      kategoriAsset.listKategoriAsset.data &&
      kategoriAsset.listKategoriAsset.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListKategoriAsset(tmp);
    if (kategoriAsset.listKategoriAsset.length != 0) {
      setPending(false);
    }
  }, [kategoriAsset.listKategoriAsset]);

  React.useEffect(() => {
    getKategoriAsset(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>
              Kategori Asset
            </div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Kategori
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listKategoriAsset}
                  pagination
                  customStyles={customStyles}
                  paginationPerPage={itemsPerPage}
                  onChangePage={handlePageChange}
                  progressPending={pending}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="md"
        show={modal}
        onHide={() => {
          setModal(false);
          setNamaKategori("");
          setSingkatan("");
          setPembagi(0);
          setJenisBatas("");
          setBatas(0);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Kategori
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Kategori <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaKategori(e.target.value);
                    }}
                    placeholder="Nama Kategori"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Singkatan Kategori <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setSingkatan(e.target.value);
                    }}
                    placeholder="Kode"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pembagi (Dalam Bulan)<span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setPembagi(e.target.value);
                    }}
                    placeholder="Pembagi"
                    type="number"
                    min={1}
                    value={pembagi}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Jenis Batas <span className="star">*</span>
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={jenisBatas}
                    onChange={(value) => {
                      setJenisBatas(value);
                    }}
                    options={[
                      { value: "atas", label: "Di Atas" },
                      { value: "bawah", label: "Di Bawah" },
                    ]}
                    placeholder="Pilih Jenis Batas"
                  />
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Batas <span className="star">*</span>
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Batas"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setBatas(value);
                    }}
                    value={batas}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addKategori}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
      {/* Modal Edit */}
      {/* <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setNamaDivisi("");
          setIdDivisi("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Edit Divisi
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Divisi
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaDivisi(e.target.value);
                    }}
                    placeholder="Nama Divisi"
                    type="text"
                    value={namaDivisi}
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={editNamaDivisi}
              >
                Edit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal> */}
    </>
  );
}

export default KategoriAsset;
