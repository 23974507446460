import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getLokasiAsset(dispatch, data, history) {
  try {
    const response = await baseAxios("/lokasiAssetWeb/getLokasiAsset", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLLOKASIASSET", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahLokasiAsset(data) {
  try {
    const response = await baseAxios.post(
      "/lokasiAssetWeb/addLokasiAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function nonAktifLokasiAsset(data) {
  try {
    const response = await baseAxios.post(
      "/lokasiAssetWeb/nonAktifLokasiAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteLokasiAsset(data) {
  try {
    const response = await baseAxios.post(
      "/lokasiAssetWeb/deleteLokasiAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
