import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getPesanLangsung(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/orderWeb/getOrderanPl?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusProduk=${data.statusProduk}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_PESANLANGSUNG", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function updatePesanLangsung(dispatch, data, history) {
  try {
    const response = await baseAxios.patch(`/orderWeb/updatePl`, data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportPl(dispatch, data) {
  try {
    const response = await baseAxios(
      `/orderWeb/exportPl?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
        responseType: "blob",
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}
