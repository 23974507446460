import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export async function getTotalOrderByStatus(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getTotalOrderByStatus?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLORDERSTATUS", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getDetailOrderByStatus(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getDetailOrderByStatus?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&statusOrder=${data.statusOrder}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
    // dispatch({ type: "SET_ALLORDERSTATUS", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPl(dispatch, data, history) {
  try {
    const response = await baseAxios(`/dashboardWeb/getTotalPl`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALPL", data: response.data.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPo(dispatch, data, history) {
  try {
    const response = await baseAxios(`/dashboardWeb/getTotalPo`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALPO", data: response.data.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPlProgres(dispatch, data, history) {
  try {
    const response = await baseAxios(`/dashboardWeb/getTotalPlProgres`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALPLPROGRES", data: response.data.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPoProgres(dispatch, data, history) {
  try {
    const response = await baseAxios(`/dashboardWeb/getTotalPoProgres`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALPOPROGRES", data: response.data.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalOmset(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getTotalOmset?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_TOTALOMSET", data: response.data.totalNominal });
    dispatch({ type: "SET_TOTALDP", data: response.data.totalDp });
    dispatch({ type: "SET_TOTALLN", data: response.data.totalLunas });
    dispatch({
      type: "SET_TOTALBELUMBAYAR",
      data: response.data.totalBelumBayar,
    });
    dispatch({ type: "SET_LISTDP", data: response.data.listDp });
    dispatch({ type: "SET_LISTLUNAS", data: response.data.listLunas });
    dispatch({
      type: "SET_LISTBELUMBAYAR",
      data: response.data.listBelumBayar,
    });
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikOmset(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getGrafikOmset?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_GRAFIKOMSET", data: response.data.data });
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikStok(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getGrafikStok?page=${data.page}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_GRAFIKSTOK", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikTopProduk(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/dashboardWeb/getGrafikTopProduk?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&page=${data.page}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_GRAFIKTOPPRODUK", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getTotalNominalRekening(dispatch, data, history) {
  try {
    const response = await baseAxios(`/dashboardWeb/getTotalSimpananRekening`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALNOMINALREKENING", data: response.data });
  } catch (err) {
    return err.response;
  }
}
