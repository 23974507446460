const initialState = {
  listKategoriAsset: [],
};

const kategoriAssetReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLKATEGORIASSET":
      return {
        ...state,
        listKategoriAsset: data,
      };
    default:
      return state;
  }
};
export default kategoriAssetReducer;
