const initialState = {
  listLokasiAsset: [],
};

const lokasiAssetReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLLOKASIASSET":
      return {
        ...state,
        listLokasiAsset: data,
      };
    default:
      return state;
  }
};
export default lokasiAssetReducer;
