import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function orderProduk(data) {
  try {
    const response = await baseAxios.post("/orderWeb/addOrder", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getOrder(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/orderWeb/getOrder?page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLORDERPRODUK", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function cetakSo(data) {
  try {
    const response = await baseAxios(`/orderWeb/cetakSoPdf?idSo=${data.idSo}`, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cancelSo(data) {
  try {
    const response = await baseAxios.post("/orderWeb/cancelSo", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function editEmail(data) {
  try {
    const response = await baseAxios.post("/orderWeb/gantiEmail", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function kirimEmailSo(data) {
  try {
    const response = await baseAxios.post("/orderWeb/kirimEmail", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportSo(dispatch, data) {
  try {
    const response = await baseAxios(
      `/orderWeb/exportSo?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
        responseType: "blob",
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateStatusStor(data) {
  try {
    const response = await baseAxios.patch("/orderWeb/updateSetor", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cancelPl(data) {
  try {
    const response = await baseAxios.patch("/orderWeb/cancelPl", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
