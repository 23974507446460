import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getLokasiAsset,
  tambahLokasiAsset,
  nonAktifLokasiAsset,
  deleteLokasiAsset,
} from "stores";
import CurrencyInput from "react-currency-input-field";

function LokasiAsset() {
  const dispatch = useDispatch();
  const lokasiAsset = useSelector((state) => state.lokasiAssetReducer);
  const [listLokasiAsset, setListLokasiAsset] = React.useState([]);
  const [namaLokasi, setNamaLokasi] = React.useState("");
  const [singkatan, setSingkatan] = React.useState("");
  const [idLokasi, setIdLokasi] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  //   const [data, setData] = React.useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={() => {
                Swal.fire({
                  title: `Apakah anda yakin untuk ${
                    row.softDelete ? "mengaktifkan" : "menonaktifkan"
                  } lokasi ini?`,
                  text: "Pastikan yang anda lakukan sudah benar!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok!",
                  cancelButtonText: "Batal!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Loading...!",
                      text: "Proses ubah status lokasi sedang dilakukan,tunggu proses hingga selesai!",
                      icon: "warning",
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    nonAktifLokasiAsset({
                      idLokasi: row.idLokasi,
                      softDelete: row.softDelete ? false : true,
                    }).then((response) => {
                      if (response.data.status === 200) {
                        Swal.fire({
                          title: response.data.message,
                          icon: "success",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        getLokasiAsset(dispatch);
                      } else {
                        setPending(false);
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: !response.data
                            ? "Gagal ubah status lokasi"
                            : response.data.message,
                        });
                      }
                    });
                  }
                });
              }}
              style={{
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                margin: 10,
              }}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-48903503">
                    {row.softDelete ? "Non-Aktif" : "Aktif"}
                  </Tooltip>
                }
              >
                <i
                  style={{
                    color: row.softDelete ? "red" : "green",
                    fontSize: 16,
                  }}
                  className={row.softDelete ? "fas fa-times" : "fas fa-check"}
                ></i>
              </OverlayTrigger>
            </div>
            <div
              onClick={() => {
                Swal.fire({
                  title: `Apakah anda yakin untuk menghapus lokasi ini?`,
                  text: "Pastikan yang anda lakukan sudah benar!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok!",
                  cancelButtonText: "Batal!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Loading...!",
                      text: "Proses hapus lokasi sedang dilakukan,tunggu proses hingga selesai!",
                      icon: "warning",
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    deleteLokasiAsset({
                      idLokasi: row.idLokasi,
                    }).then((response) => {
                      if (response.data.status === 200) {
                        Swal.fire({
                          title: response.data.message,
                          icon: "success",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        getLokasiAsset(dispatch);
                      } else {
                        setPending(false);
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: !response.data
                            ? "Gagal hapus lokasi"
                            : response.data.message,
                        });
                      }
                    });
                  }
                });
              }}
              style={{
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                margin: 10,
              }}
            >
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
              >
                <i
                  style={{ color: "#FF0000", fontSize: 16 }}
                  className="fas fa-trash"
                ></i>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      name: "ID Lokasi",
      width: "200px",
      selector: (row) => row.idLokasi,
    },
    {
      name: "Nama Lokasi",
      width: "150px",
      selector: (row) => row.namaLokasi,
    },
    {
      name: "Singkatan",

      center: true,
      selector: (row) => row.singkatan,
    },
  ];

  const addLokasi = () => {
    setPending(true);
    tambahLokasiAsset({
      namaLokasi: namaLokasi,
      singkatan: singkatan.toUpperCase(),
    }).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setNamaLokasi("");
        setSingkatan("");

        setModal(false);
        getLokasiAsset(dispatch);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan lokasi"
            : response.data.message,
        });
      }
    });
  };

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      lokasiAsset.listLokasiAsset.data &&
      lokasiAsset.listLokasiAsset.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListLokasiAsset(tmp);
    if (lokasiAsset.listLokasiAsset.length != 0) {
      setPending(false);
    }
  }, [lokasiAsset.listLokasiAsset]);

  React.useEffect(() => {
    getLokasiAsset(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>Lokasi Asset</div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Lokasi
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listLokasiAsset}
                  pagination
                  customStyles={customStyles}
                  paginationPerPage={itemsPerPage}
                  onChangePage={handlePageChange}
                  progressPending={pending}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="md"
        show={modal}
        onHide={() => {
          setModal(false);
          setNamaLokasi("");
          setSingkatan("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Lokasi
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Lokasi <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaLokasi(e.target.value);
                    }}
                    placeholder="Nama Lokasi"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Singkatan Lokasi <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setSingkatan(e.target.value);
                    }}
                    placeholder="Singkatan"
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addLokasi}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
      {/* Modal Edit */}
      {/* <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setNamaDivisi("");
          setIdDivisi("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Edit Divisi
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Divisi
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaDivisi(e.target.value);
                    }}
                    placeholder="Nama Divisi"
                    type="text"
                    value={namaDivisi}
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={editNamaDivisi}
              >
                Edit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal> */}
    </>
  );
}

export default LokasiAsset;
