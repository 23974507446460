import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getPoProses(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/poWeb/getPo?statusPo=Proses&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_POPROSES", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getPoSelesai(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/poWeb/getPo?statusPo=Selesai&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_POSELESAI", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function updatePo(dispatch, data, history) {
  try {
    const response = await baseAxios.patch(`/poWeb/updatePoSelesai`, data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportPo(dispatch, data) {
  try {
    const response = await baseAxios(
      `/poWeb/exportPo?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
        responseType: "blob",
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateStatusBayarPo(data) {
  try {
    const response = await baseAxios.post("/poWeb/updatePoLunas", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cancelPo(data) {
  try {
    const response = await baseAxios.patch("/poWeb/cancelPo", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
