const initialState = {
  listRekening: [],
};

const rekeningReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLREKENING":
      return {
        ...state,
        listRekening: data,
      };
    default:
      return state;
  }
};
export default rekeningReducer;
