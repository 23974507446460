import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getInvSj(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/suratJalanWeb/getInvoiceForSj?page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLINVSJ", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahSj(data) {
  try {
    const response = await baseAxios.post(
      "/suratJalanWeb/addSuratJalan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function editStatusSj(data) {
  try {
    const response = await baseAxios.post(
      "/suratJalanWeb/updateStatusSj",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cetakSj(data) {
  try {
    const response = await baseAxios(
      `/suratJalanWeb/cetakSjPdf?idSo=${data.idSo}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportSj(dispatch, data) {
  try {
    const response = await baseAxios(
      `/suratJalanWeb/exportSj?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
        responseType: "blob",
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}
