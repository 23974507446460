import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getKebutuhan(dispatch, data, history) {
  try {
    const response = await baseAxios("/kebutuhanWeb/getKebutuhan", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLKEBUTUHAN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahKebutuhan(data) {
  try {
    const response = await baseAxios.post("/kebutuhanWeb/addKebutuhan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateKebutuhan(data) {
  try {
    const response = await baseAxios.patch(
      "/kebutuhanWeb/updateKebutuhan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteKebutuhan(data) {
  try {
    const response = await baseAxios.patch(
      "/kebutuhanWeb/deleteKebutuhan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
